@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Capture-it";
    src: local('Capture-it'),  url("./assets/fonts/Capture-it.ttf") format('truetype');
}


@font-face {
    font-family: "Josefin Sans";
    src: local('Josefin Sans'),  url("./assets/fonts/static/JosefinSans-Light.ttf") format('truetype');
}

@font-face {
    font-family: "Josefin Sans Bold";
    src: local('Josefin Sans'),  url("./assets/fonts/static/JosefinSans-Bold.ttf") format('truetype');
}

body {
    font-family: 'Josefin Sans', sans-serif;
}

.navbar {
    background-image: url('./assets/images/banner.jpg');
}

.brand a {
    font-family: 'Josefin Sans Bold', sans-serif;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}